import React from "react"
import styled from "styled-components"

const StyledFooter = styled.footer`
  margin-top: 3rem;
  background: #fff;
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px -2px 10px rgba(127, 127, 127, 0.2);
  .footer-instagram-container {
    img {
      transition: 200ms ease-in-out;
      filter: invert(1);
      &:hover {
        filter: invert(0.8);
      }
    }
  }
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 1rem;
    .footer-instagram-container {
      margin-bottom: 1rem;
    }
  }
`

const Footer = () => {
  return (
    <StyledFooter>
      <div className="footer-instagram-container">
        <a href="https://instagram.com/agnessuto.is" target="__blank">
          <img src="/images/ig.png" alt="instagram-logo" />
        </a>
      </div>
      <a href="mailto:agnessuto.is@gmail.com">agnessuto.is@gmail.com</a>
      <p>(+354) 771 75 58</p>
    </StyledFooter>
  )
}

export default Footer
