import React from "react"
import styled from "styled-components"

const StyledButton = styled.button`
  border: 1px #000 solid;
  border-radius: 0;
  font-size: 1rem;
  padding: 1rem 2rem;
  cursor: pointer;
  background: #fff;
  text-transform: uppercase;
  transition: 200ms ease-in-out;
  &:hover {
    color: #fff;
    background: #000;
    border: 1px #fff solid;
  }
`

const Button = props => {
  return (
    <StyledButton
      className={props.className}
      onClick={props.onClick}
      type={props.type}
    >
      {props.children}
    </StyledButton>
  )
}

export default Button
