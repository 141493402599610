import React from "react"
import styled from "styled-components"
import Navbar from "./navbar"
import Footer from "./footer"
import { Helmet } from "react-helmet"
import "./index.css"
import { useStaticQuery, graphql } from "gatsby"
import Loader from "./loader"

const StyledLayout = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  .layout-inner {
    flex-grow: 1;
  }
  .loader-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: 200ms ease-in-out;
    z-index: 200;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    pointer-events: none;
    &.active {
      opacity: 1;
      pointer-events: initial;
    }
  }
`

const Layout = props => {
  const query = useStaticQuery(graphql`
    query {
      contentfulGeneral {
        homePageTopImage {
          fluid {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  `)
  return (
    <>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;700&display=swap"
          rel="stylesheet"
        />
        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href="/apple-icon-57x57.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href="/apple-icon-60x60.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="/apple-icon-72x72.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href="/apple-icon-76x76.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href="/apple-icon-114x114.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href="/apple-icon-120x120.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href="/apple-icon-144x144.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href="/apple-icon-152x152.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-icon-180x180.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href="/android-icon-192x192.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="96x96"
          href="/favicon-96x96.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/manifest.json" />
        {/* <meta name="msapplication-TileColor" content="#ffffff" /> */}
        <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
        {/* <meta name="theme-color" content="#ffffff" /> */}
      </Helmet>
      <StyledLayout>
        <div className="loader-container" id="layoutLoader">
          <Loader color="#c7c7c7" dualRing />
        </div>
        <Navbar image={query} />
        <div className="layout-inner">{props.children}</div>
        <Footer />
      </StyledLayout>
    </>
  )
}

export default Layout
