import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { Link, graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Button from "./button"
import Img from "gatsby-image"
import Hamburger from "./hamburger"

const StyledNavbar = styled.nav`
  .nav-buttons {
    button {
      margin: 0 1rem;
    }
  }

  .sticky-nav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    background: #fff;
    box-shadow: 0px 4px 10px rgba(127, 127, 127, 0.2);
    opacity: 0;
    transition: 400ms ease-in-out;
    pointer-events: none;
    &.active {
      opacity: 1;
      pointer-events: initial;
    }
    &-left {
      width: 100px;
      &.mobile {
        display: none;
      }
    }
    &-right {
      &.mobile {
        display: none;
      }
      ul {
        display: flex;
        li {
          text-transform: uppercase;
          padding: 0 1rem;
          position: relative;
          &:after {
            transition: 200ms ease-in-out;
            position: absolute;
            bottom: 0;
            left: 1rem;
            right: 0;
            width: 0;
            height: 1px;
            background: #000;
            content: "";
          }
          &:hover {
            &:after {
              width: calc(100% - 2rem);
            }
          }
        }
      }
    }
  }
  .navbar-outer {
    padding: 3rem 1rem 3rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    .navbar-logo-section {
      margin-bottom: 1rem;
      width: 50vw;
      h1 {
        text-transform: uppercase;
        font-size: 5rem;
        margin: 0;
      }
    }
    .navbar-links-desktop {
      ul {
        display: flex;
        li {
          text-transform: uppercase;
          padding: 0 1rem;
          position: relative;
          &:after {
            transition: 200ms ease-in-out;
            position: absolute;
            bottom: 0;
            left: 1rem;
            right: 0;
            width: 0;
            height: 1px;
            background: #000;
            content: "";
          }
          &:hover {
            &:after {
              width: calc(100% - 2rem);
            }
          }
        }
      }
    }
    .navbar-button-section {
      margin-top: 2rem;
    }
    .navbar-hamburger {
      display: none;
    }
    .navbar-dropdown {
      display: none;
    }
  }
  @media (max-width: 768px) {
    position: relative;
    z-index: 1;
    .navbar-outer {
      .navbar-logo-section {
        width: 70vw;
        margin: 0;
      }
      .navbar-links-desktop {
        display: none;
      }
      .navbar-button-section {
        text-align: center;
        button {
          margin-bottom: 1rem;
        }
      }
      .navbar-hamburger {
        display: block !important;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 2350;
        padding: 1rem;
      }
      .navbar-dropdown {
        display: block;
        width: 100vw;
        position: fixed;
        padding-top: 4rem;
        top: -100vh;
        left: 0;
        right: 0;
        background: #fff;
        z-index: 1000;
        box-shadow: 0px 4px 10px rgba(127, 127, 127, 0.2);
        transition: 400ms ease-in-out;
        &.active {
          top: 0;
        }
        ul {
          display: flex;
          flex-direction: column;
          align-items: center;
          li {
            padding: 1rem;
            text-transform: uppercase;
          }
        }
      }
    }
    .sticky-nav {
      padding: 0.3rem 1rem;
      align-items: center;
    }
    .sticky-nav-right {
      display: none;
      &.mobile {
        display: block;
      }
    }
    .sticky-nav-left {
      display: none;
      &.mobile {
        display: block;
      }
    }
  }
`

const Navbar = props => {
  const [hamburgerActive, setHamburgerActive] = useState(false)

  const links = [
    {
      txt: "Heim",
      link: "/",
    },
    {
      txt: "Myndir",
      link: "/myndir",
    },
    {
      txt: "Teikningar",
      link: "/teikningar",
    },
    {
      txt: "Um mig",
      link: "/um",
    },
  ]
  const ul = (
    <ul>
      {links.map(link => (
        <Link to={link.link} key={link.link}>
          <li>{link.txt}</li>
        </Link>
      ))}
    </ul>
  )

  const background = [
    "linear-gradient(rgba(255,255,255,0.7), rgba(255,255,255,0.7))",
    props.image.contentfulGeneral.homePageTopImage.fluid,
  ]

  const buttons = (
    <div className="nav-buttons">
      <Link to="/panta-teikningu">
        <Button>Panta teikningu</Button>
      </Link>
    </div>
  )

  const [scrolled, setScrolled] = useState(false)

  useEffect(() => {
    document.addEventListener("scroll", () => {
      const threshold = 250
      if (window.scrollY >= threshold) {
        setScrolled(true)
      } else {
        setScrolled(false)
      }
    })
  }, [])

  const query = useStaticQuery(graphql`
    query {
      contentfulGeneral {
        logo {
          fluid {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  `)

  const logoContent = (
    <Link to="/">
      <Img fluid={query.contentfulGeneral.logo.fluid} alt="Agnes Suto" />
    </Link>
  )

  const hamburger = (
    <Hamburger
      color="#000"
      active={hamburgerActive}
      onClick={() => {
        setHamburgerActive(!hamburgerActive)
      }}
    />
  )

  return (
    <StyledNavbar>
      <div
        className={`sticky-nav ${scrolled || hamburgerActive ? "active" : ""}`}
      >
        <div className="sticky-nav-left">{logoContent}</div>
        <div className="sticky-nav-left mobile">{logoContent}</div>
        <div className="sticky-nav-right">{ul}</div>
        <div className="sticky-nav-right mobile">{hamburger}</div>
      </div>
      <BackgroundImage
        fluid={background}
        style={{
          width: "100vw",
          backgroundAttachment: "fixed",
        }}
      >
        <div className="navbar-outer">
          <div className="navbar-logo-section">{logoContent}</div>
          <div className="navbar-hamburger">{hamburger}</div>
          <div className={`navbar-dropdown ${hamburgerActive ? "active" : ""}`}>
            {/* <div className="dropdown-logo">{logoContent}</div> */}
            <div
              onClick={() => {
                setHamburgerActive(false)
              }}
            >
              {ul}
            </div>
          </div>
          <div className="navbar-links-desktop">{ul}</div>
          <div className="navbar-button-section">{buttons}</div>
        </div>
      </BackgroundImage>
    </StyledNavbar>
  )
}

export default Navbar
